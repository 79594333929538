import tw, { styled } from 'twin.macro';

const Container = styled.div`
  ${tw`mt-6 sm:mt-10 h-full`}

  & > h1 {
    ${tw`text-5xl mb-4`}
  }

  & > h2 {
    ${tw`text-4xl mb-3`}
  }

  & > h3 {
    ${tw`text-3xl mb-2`}
  }

  & > h1,
  > h2,
  > h3 {
    ${tw`text-teal`}
  }

  & > span {
    ${tw`text-base`}
  }

  & > ol > li {
    ${tw`mb-4`}
  }

  & p {
    ${tw`mb-6`}
  }

  & > ol {
    ${tw`list-decimal pl-12`}
  }

  & > ul {
    ${tw`list-disc pl-12`}
  }
`;

export default Container;
